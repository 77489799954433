import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { DomainStrings, Portal, PortalLink, SideMenu } from 'interfaces'
import { Dispatch } from 'react'
import { NavigateFunction } from 'react-router-dom'
import {
  resetPortal,
  setBanner,
  setPortal,
  setPortalPage,
} from 'utils/redux/slices/userInterfaceSlice'
import { RootState } from 'utils/redux/store'

/**
 * Builds the metadata for each link that renders in the Project Portal sidemenu.
 * @param userId
 * @returns {SideMenuInterface} Context for Project Portal sidemenu
 */
export const buildProjectPortalLinks = (projectId: string): PortalLink[] => {
  let urlProjectId = 'sandbox'
  if (projectId) urlProjectId = projectId

  return [
    {
      domain: 'project',
      headerTitle: 'Product Details',
      icon: 'description',
      label: 'Product Details',
      route: `/project/${urlProjectId}`,
    },
    {
      domain: 'project',
      headerTitle: 'Team Members',
      icon: 'group',
      label: 'Team',
      route: `/project/${urlProjectId}/team`,
    },
    {
      domain: 'project',
      headerTitle: 'Scrum Calendar',
      icon: 'calendar',
      label: 'Scrum Calendar',
      route: `/project/${urlProjectId}/calendar`,
    },
    {
      domain: 'project',
      //TODO: make this value dynamic
      headerTitle: 'Sprints',
      icon: 'tasks',
      label: 'Kanban Board',
      route: `/project/${urlProjectId}/tasks`,
    },
  ]
}

/**
 * Builds the metadata for each link that renders in the Settings sidemenu.
 * @param userId
 * @returns {SideMenuInterface} Context for Settings sidemenu
 */
export const buildSettingsPortalLinks = (userId: string): PortalLink[] => [
  {
    domain: 'settings',
    icon: 'email',
    label: 'Email',
    route: `/users/${userId}/settings/email`,
  },
  {
    domain: 'settings',
    icon: 'lock',
    label: 'Password',
    route: `/users/${userId}/settings/password`,
  },
]

/**
 * Builds the redux object for the Settings sidemenu.
 * @param userId
 * @returns {SideMenuInterface} Context for settings sidemenu
 */
export const buildSettingsPortal = (userId: string) => {
  const portal: Portal = {
    active: true,
    type: 'settings',
  }

  const sideMenu: SideMenu = {
    active: true,
    links: buildSettingsPortalLinks(userId),
    title: 'Settings',
  }
  return { portal, sideMenu }
}

/**
 * Builds the redux object for the Project Portal sidemenu.
 * @param projectId
 * @returns {SideMenuInterface} Context for Project Portal sidemenu
 */
export const buildProjectPortal = (projectId: string, headerTitle?: string) => {
  const portal: Portal = {
    active: true,
    headerTitle: headerTitle || 'Product Details',
    type: 'project',
  }

  const sideMenu: SideMenu = {
    active: true,
    links: buildProjectPortalLinks(projectId),
    title: 'Project Portal',
  }

  return { portal, sideMenu }
}

/**
 * Dispatches side menu state based on type of domain.
 * @param dispatch Instantiated dispatcher
 * @param domain Type of domain: "project" | "settings"
 * @param projectId
 * @param userId
 */
export const buildPortal = (
  dispatch: ThunkDispatch<RootState, undefined, AnyAction> &
    Dispatch<AnyAction>,
  domain: DomainStrings,
  routeId: string,
  experience?: string,
  headerTitle?: string
) => {
  if (domain === 'project') {
    dispatch(setPortal(buildProjectPortal(routeId, headerTitle)))
    determineBanner(dispatch, experience)
  } else if (domain === 'settings') {
    dispatch(setPortal(buildSettingsPortal(routeId)))
  } else {
    dispatch(resetPortal())
  }
}

const determineBanner = (dispatch, experience) => {
  if (experience === 'sandbox') {
    dispatch(setBanner({ active: true, type: 'sandbox' }))
  } else if (experience === 'waitlist') {
    dispatch(setBanner({ active: true, type: 'waitlist' }))
  }
}

export const changePortalPage = (dispatch, headerTitle) => {
  dispatch(setPortalPage(headerTitle))
}

/**
 * Checks if the current URL would belong to a menu link in the sidemenu
 * @returns The type of portal the URL belongs to.
 */
export const doesUrlBelongToPortal = (pathname, userId, projectId) => {
  if (buildSettingsPortalLinks(userId).find(link => link.route === pathname)) {
    return 'settings'
  } else if (
    buildProjectPortalLinks(projectId).find(link => link.route === pathname)
  ) {
    return 'project'
  } else return ''
}

/**
 * Looks for the menulink object the URL belongs to.
 * @returns The entire menu link object generated by the PortalLink builder.
 */
export const determinePortalFromUrl = (
  pathname,
  userId,
  projectId
): PortalLink | undefined => {
  if (pathname.includes('settings')) {
    return buildSettingsPortalLinks(userId).find(
      link => link.route === pathname
    )
  } else {
    return buildProjectPortalLinks(projectId).find(
      link => link.route === pathname
    )
  }
}

export const extractDomain = path => {
  return path.split('/').filter(Boolean)[0]
}

/**
 * Checks if window width is small enough to be considered mobile
 * @returns Boolean
 */
export const isMobileWidth = () => window.innerWidth <= 853

/**
 * Simply adds domain to Location state when routing user. Required for SideMenu usage.
 * @param navigate Callback returned from useNavigate()
 * @param route URL for user routing
 * @param domain Type of domain: "project" | "settings"
 */
export const navigateToDomain = (
  navigate: NavigateFunction,
  route: string,
  domain: DomainStrings
) => navigate(route, { state: { domain } })
