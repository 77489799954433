export const timelineData = [
  {
    date: 'Day 1',
    title: 'Kickoff Meeting',
    description: (
      <div>
        <p>Welcome to Collabify! Meet your team and plan your project.</p>
      </div>
    ),
  },
  {
    date: 'Week 1 - 3',
    title: 'Assign and complete stories',
    description: (
      <div>
        <p>Homepage</p>
        <p>Menu</p>
        <p>Ordering flow</p>
        <p>User account log in</p>
        <p>Reservation flow</p>
        <p>Contact us</p>
      </div>
    ),
  },
  {
    date: 'Week 4',
    title: 'Testing and refinement',
    description: (
      <div>
        <p>After testing and refinement, deploy your live site!</p>
        <p>Then submit your completed project.</p>
      </div>
    ),
  },
  {
    date: 'TBD',
    title: 'Presentation',
    description: (
      <div>
        <p>
          Present your team's work to professional Product Managers, UX
          Designers, and Software Engineers.
        </p>
        <br></br>
        <p>
          Use the feedback you receive to present your work in interviews with
          confidence!
        </p>
      </div>
    ),
  },
]
