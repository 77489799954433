import { MeetingText } from 'interfaces'

export const initialMeetingText: MeetingText = {
  description: '',
  meetingLink: '',
  summary: '',
}

export const staticEmails = [
  'elizabeth@engineer.com',
  'evie@engineer.com',
  'polly@product.com',
  'dana@designer.com',
  'david@designer.com',
  'svc.jira.swe@gmail.com',
]

export const timeOptions = [
  '12:00 AM',
  '12:30 AM',
  '1:00 AM',
  '1:30 AM',
  '2:00 AM',
  '2:30 AM',
  '3:00 AM',
  '3:30 AM',
  '4:00 AM',
  '4:30 AM',
  '5:00 AM',
  '5:30 AM',
  '6:00 AM',
  '6:30 AM',
  '7:00 AM',
  '7:30 AM',
  '8:00 AM',
  '8:30 AM',
  '9:00 AM',
  '9:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '1:00 PM',
  '1:30 PM',
  '2:00 PM',
  '2:30 PM',
  '3:00 PM',
  '3:30 PM',
  '4:00 PM',
  '4:30 PM',
  '5:00 PM',
  '5:30 PM',
  '6:00 PM',
  '6:30 PM',
  '7:00 PM',
  '7:30 PM',
  '8:00 PM',
  '8:30 PM',
  '9:00 PM',
  '9:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
]

export const usTimeZones = [
  { value: 'America/St_Johns', name: 'Newfoundland Time (NT)' },
  { value: 'America/Puerto_Rico', name: 'Atlantic Time (AT)' },
  { value: 'America/New_York', name: 'Eastern Time (ET)' },
  { value: 'America/Chicago', name: 'Central Time (CT)' },
  { value: 'America/Denver', name: 'Mountain Time (MT)' },
  { value: 'America/Los_Angeles', name: 'Pacific Time (PT)' },
  { value: 'America/Anchorage', name: 'Alaska Time (AKT)' },
  { value: 'Pacific/Honolulu', name: 'Hawaii-Aleutian Time (HT)' },
]
